<template>
	<div class="social-media">
		<img src="@/assets/services-service/Social.png" alt="social" />
	</div>
</template>

<script>
export default {
	setup() {
		return {};
	},
};
</script>

<style lang="scss" scoped src="./social-media.scss" />
