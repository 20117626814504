<template>
	<div class="article__share">
		Share with:
		<div class="article__links">
			<img
				class="article__link"
				src="@/assets/icons/twitter.png"
				alt="twitter"
			/>
			<img
				class="article__link"
				src="@/assets/icons/instagram.png"
				alt="instagram"
			/>
			<img
				class="article__link"
				src="@/assets/icons/facebook.png"
				alt="facebook"
			/>
			<img class="article__link" src="@/assets/icons/mail.png" alt="mail" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'ArticleShare',
};
</script>
